<template>
  <div class="announcements">
    <div class="container">
      <div class="announcements__inner">
        <div class="title fs24 tl pb45">{{ title }}</div>
        <div class="announcements__content">
          <div class="announcement">
            <div class="cover">
              <img src="../../assets/img/images/hero-img.jpg" alt="" />
            </div>
            <div class="announcement__info">
              <h3 class="announcement-title">{{ newsTitle }}</h3>
              <p class="text">
                {{ body1 }}
              </p>
              <p class="text">
                {{ body2 }}
              </p>
              <p class="text">
                {{ body3 }}
                <router-link :to="href"><strong>{{ linkText }}</strong></router-link>
              </p>
              <span class="date">{{ date }}</span>
            </div>
          </div>
          <div class="announcement" v-for="vacancy in vacancies" :key="vacancy.id">
            <div class="cover">
              <img :src="require(`../../assets/img/images/${vacancy.img}`)" alt="" />
            </div>
            <div class="announcement__info">
              <h3 class="announcement-title">{{ vacancy.title }}</h3>
              <p class="text">
                {{ vacancy.body }}
                <a rel="noreferrer" target="_blank" :href="vacancy.href"><strong>{{ vacancy.linkText }}</strong></a>
              </p>
              <span class="date">{{ vacancy.date }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vacancies from '../../data/vacancies.json';
export default {
  name: 'Announcements',
  props: {
    title: String,
    newsTitle: String,
    body1: String,
    body2: String,
    body3: String,
    date: String,
    href: String,
    linkText: String
  },
  data() {
    return {
      vacancies: vacancies
    };
  }
};
</script>

<style>
.announcement {
  display: flex;
  align-items: center;
  gap: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-bottom: 25px;
  padding: 15px;
}

.announcement__info {
  margin-bottom: 15px;
}

.announcement-title {
  margin-bottom: 15px;
}

.cover {
  max-width: 250px;
  max-height: 250px;
}

.cover img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.date {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  padding-top: 20px;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
  .announcement {
    flex-direction: column;
    gap: 10px;
  }

  .announcement .text {
    font-size: 14px;
  }

  .announcements__inner {
    padding: 0;
  }

  .cover {
    max-width: 100%;
    max-height: auto;
    margin-bottom: 15px;
  }
}
</style>
