<template>
  <div class="fin-mediator">
    <div class="container">
      <div class="fin-mediator__inner">
        <div class="title fs25 tl pb45">{{ title }}</div>
        <div class="fin-mediator__content">
          <div class="fin-mediator__content-left">
            <img src="@/assets/img/logo/fin-dark.jpg" alt="fin-mediator" />
          </div>
          <div class="fin-mediator__content-right">
            <div class="content-block">
              <p>
                {{ $t('usefulInformation.financialMediator.body') }}
              </p>
            </div>
            <div class="content-block">
              <p>{{ $t('usefulInformation.financialMediator.address') }}</p>
              <p>{{ $t('usefulInformation.financialMediator.place') }}</p>
              <p>{{ $t('usefulInformation.financialMediator.phone') }}</p>
              <p>{{ $t('usefulInformation.financialMediator.fax') }}</p>
              <p>{{ $t('usefulInformation.financialMediator.website') }}</p>
            </div>
            <div class="content-block">
              <p>
                {{ $t('usefulInformation.financialMediator.workingHours') }}
              </p>
              <p>{{ $t('usefulInformation.financialMediator.days') }}</p>
              <p>{{ $t('usefulInformation.financialMediator.break') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="updated">{{ $t('updated') }} 28.02.2022</p>
  </div>
</template>

<script>
export default {
  name: 'Financial Systems Mediator',
  props: {
    title: String
  }
};
</script>

<style>
.fin-mediator__content {
  display: flex;
  align-items: flex-start;
}
.content-block {
  margin-bottom: 40px;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
  .fin-mediator__content {
    flex-direction: column;
  }
  .content-block {
    margin-bottom: 25px;
  }
  .content-block p {
    font-size: 14px;
  }
}
</style>
